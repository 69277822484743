var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-d-flex p-d-column",staticStyle:{"width":"100%"}},[(!_vm.editMode && !_vm.createMode)?_c('div',{staticClass:"text-field p-d-flex p-ai-center"},[_c('div',{staticClass:"p-d-flex p-flex-column",staticStyle:{"flex-grow":"1"}},[_c('span',{staticClass:"task-title",staticStyle:{"overflow-wrap":"anywhere"},attrs:{"data-cy":"opened-task-title"},domProps:{"textContent":_vm._s(_vm.localValue.title)}}),_c('span',{staticClass:"task-description p-mt-2",staticStyle:{"overflow-wrap":"anywhere"},attrs:{"data-cy":"opened-task-description"},domProps:{"textContent":_vm._s(_vm.localValue.description)}})]),_c('div',[(_vm.canI('UpdateUserTask', _vm.entity))?_c('Button',{staticClass:"edit-button p-button-rounded p-button-text",attrs:{"data-cy":"button-edit","type":"button","icon":"mdi mdi-18px mdi-pencil"},on:{"click":function () {
            _vm.editMode = true
            _vm.notSavedValue = Object.assign({}, _vm.localValue)
          }}}):_vm._e()],1)]):_c('div',{staticClass:"p-d-flex",staticStyle:{"position":"relative","width":"100%"}},[_c('div',{staticClass:"p-d-flex p-flex-column",staticStyle:{"width":"100%"}},[_c('span',{staticClass:"p-input-raised p-mb-1"},[_c('InputText',{staticStyle:{"width":"100%"},attrs:{"data-cy":"input-title","type":"text","placeholder":_vm.$t("attrs.title")},on:{"blur":_vm.onBlure},model:{value:(_vm.notSavedValue.title),callback:function ($$v) {_vm.$set(_vm.notSavedValue, "title", $$v)},expression:"notSavedValue.title"}})],1),_c('span',{staticClass:"p-input-raised",staticStyle:{"width":"100%"}},[_c('Textarea',{staticStyle:{"width":"100%"},attrs:{"data-cy":"input-description","type":"text","placeholder":_vm.$t("attrs.description")},on:{"blur":_vm.onBlure},model:{value:(_vm.notSavedValue.description),callback:function ($$v) {_vm.$set(_vm.notSavedValue, "description", $$v)},expression:"notSavedValue.description"}})],1)]),(!_vm.createMode)?_c('div',{staticClass:"p-d-flex",staticStyle:{"position":"absolute","bottom":"-40px","right":"5px"}},[_c('Button',{staticClass:"p-button-rounded p-button-text",attrs:{"data-cy":"button-save-edit","type":"button","icon":"mdi mdi-18px mdi-check"},on:{"click":function () {
            _vm.localValue = _vm.notSavedValue
            _vm.editMode = false
          }}}),_c('Button',{staticClass:"p-button-rounded p-button-text",attrs:{"data-cy":"button-not-save-edit","type":"button","icon":"mdi mdi-18px mdi-close"},on:{"click":function () {
            _vm.notSavedValue = Object.assign({}, _vm.localValue)
            _vm.editMode = false
          }}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }