



import Vue from 'vue'

export default Vue.extend({
  name: 'Label',
  props: {
    entity: Object as () => {
      title?: string
      name?: string
    },
  },
})
