import './set-public-path'
import Vue from 'vue'
import VueRouter from 'vue-router'
import singleSpaVue from 'single-spa-vue'
import Button from 'primevue/button'
import VueI18n from 'vue-i18n'
import { RUSSIAN_TRANSLATIONS } from './translations/ru'
import App from './App.vue'
import Vuex from 'vuex'
import Store from './store'
import Dialog from 'primevue/dialog'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import OverlayPanel from 'primevue/overlaypanel'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Textarea from 'primevue/textarea'
import InputText from 'primevue/inputtext'
import DeleteDialog from '@/components/common/DeleteDialog.vue'
import ProgressBar from 'primevue/progressbar'
import AutoComplete from '@/components/common/AutoComplete.vue'

// @ts-ignore
import { HScroll } from '@/utils'

Vue.directive('hscroll', HScroll)
Vue.directive('tooltip', Tooltip)
Vue.component('DeleteDialog', DeleteDialog)
Vue.component('Textarea', Textarea)
Vue.component('OverlayPanel', OverlayPanel)
Vue.component('Calendar', Calendar)
Vue.component('Button', Button)
Vue.component('Dialog', Dialog)
Vue.component('Dropdown', Dropdown)
Vue.component('Toast', Toast)
Vue.component('ProgressBar', ProgressBar)
Vue.component('InputText', InputText)
Vue.component('AutoComplete', AutoComplete)

Vue.use(Vuex)
Vue.use(VueI18n)
Vue.use(ToastService)
Vue.use(VueRouter)

Vue.prototype.$primevue = { ripple: true }
Vue.config.productionTip = false
const routes = [] as any
const router = new VueRouter({
  base: __dirname,
  mode: 'history',
  routes,
})

Vue.config.productionTip = false

const locale = 'ru'
const TRANSLATIONS = {
  ru: RUSSIAN_TRANSLATIONS,
}
const i18n = new VueI18n({
  locale,
  messages: TRANSLATIONS,
})

const store = new Vuex.Store(Store)

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    store,
    router,
    i18n,
    render: (h: (app: unknown) => void) => h(App),
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = (props: any) => {
  if (props.domElement && props.vueAppend) {
    const el = document.createElement('div')
    props.domElement.appendChild(el)
    props.domElement = el
  }
  return vueLifecycles.mount(props)
}
export const unmount = vueLifecycles.unmount
