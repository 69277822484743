




























































































import { mapState } from 'vuex'
import BatchQueue from '../common/BatchQueue'

/**
 * This field need for TBO project only
 */
export default BatchQueue.extend({
  name: 'OrganizationField',
  props: {
    entity: Object as () => UserTask,
  },
  data() {
    return {
      group: {} as Group,
    }
  },
  computed: {
    ...mapState(['idsMap']),
    localOrganizationName: {
      set(val: string) {
        if (val !== this.localOrganization?.id) {
          const method = this.createMode ? 'commitEntity' : 'updateEntity'
          if (val) {
            this?.[method]({
              tboOrganizationId: val,
              assignee: this.idsMap[val]?.defaultAssignee || null,
            })
          } else {
            this?.[method]({
              tboOrganizationId: val,
            })
          }
        }
      },
      get(): string {
        return this.localOrganization?.title || ''
      },
    },
    localOrganization(): Partial<TboOrganization> {
      if (this.idsMap[this.entity.tboOrganizationId || '']) {
        return this.idsMap[this.entity.tboOrganizationId || '']
      } else {
        setTimeout(() => {
          this.entity.tboOrganizationId &&
            this.putInBatchQueue([
              { id: this.entity.tboOrganizationId, type: 'TboOrganization' },
            ])
        })
        return {}
      }
    },
    localAssignee: {
      set(val: string) {
        if (val !== this.entity.assignee) {
          const method = this.createMode ? 'commitEntity' : 'updateEntity'
          this?.[method]({
            assignee: val,
          })
        }
      },
      get(): string {
        if (this.idsMap[this.entity.assignee || '']?.fullName) {
          return this.idsMap[this.entity.assignee || '']?.fullName
        } else {
          setTimeout(() => {
            this.entity.assignee &&
              this.putInBatchQueue([{ id: this.entity.assignee, type: 'User' }])
          })
          return ''
        }
      },
    },
    groupUsersIds(): Uuid[] {
      if (this.idsMap[this.localOrganization.usersGroup || '']?.users) {
        setTimeout(() => {
          this.idsMap[this.localOrganization.usersGroup || '']?.users?.forEach(
            (id: Uuid) => {
              this.putInBatchQueue([{ id, type: 'User' }])
            },
          )
        })
        return (
          (this.idsMap[this.localOrganization.usersGroup || '']
            ?.users as Uuid[]) || []
        )
      } else {
        setTimeout(() => {
          this.localOrganization.usersGroup &&
            this.putInBatchQueue([
              { id: this.localOrganization.usersGroup, type: 'Group' },
            ])
        })
        return []
      }
    },
    usersOptions(): User[] {
      return this.groupUsersIds.map((id) => this.idsMap[id]).filter((e) => e)
    },
    tboAreaId(): ArrayElement<UserTask['relatedEntities']>['id'] | undefined {
      const tboArea = this.entity.relatedEntities.find(
        ({ type }) => type === 'TboContainerArea',
      )
      setTimeout(() => {
        tboArea?.id &&
          this.putInBatchQueue([{ id: tboArea.id, type: 'TboContainerArea' }])
      })
      return tboArea?.id
    },
    tboOrganizationsIds(): TboOrganization['id'][] {
      const organizationsIds =
        (this.idsMap[this.tboAreaId || '']?.organizations as Uuid[]) || []
      if (organizationsIds.length > 0) {
        setTimeout(() => {
          organizationsIds.forEach((id: Uuid) => {
            this.putInBatchQueue([{ id, type: 'TboOrganization' }])
          })
        })
      }
      return organizationsIds
    },
    tboOrganizationOptions(): TboOrganization[] {
      return this.tboOrganizationsIds
        .map((id) => this.idsMap[id])
        .filter((e) => e)
    },
  },
})
