





















import { mapState } from 'vuex'
import BatchQueue from '../common/BatchQueue'
import User from '../common/User.vue'
import Label from '../common/Label.vue'

export default BatchQueue.extend({
  name: 'ReferenceField',
  props: {
    entity: Object,
    options: Object as () => {
      title: string
      scope: string
      editable: boolean
      entityType: string
      labelField: string
    },
  },
  data() {
    return {
      Label,
      componentMap: {
        User,
        Label,
      },
      loading: false,
      valueOptions: [] as any,
    }
  },
  watch: {
    entity: {
      handler() {
        if (
          !this.idsMap[this.entity[this.options.title]]?.[
            this.options.labelField
          ]
        ) {
          this.refresh()
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['idsMap']),
    localValue: {
      set(val: string) {
        if (val !== this.entity[this.options.title]) {
          const method = this.createMode ? 'commitEntity' : 'updateEntity'
          this?.[method]({
            [this.options.title]: val,
          })
        }
      },
      get(): string {
        return this.idsMap[this.entity[this.options.title]]
          ? this.idsMap[this.entity[this.options.title]]?.[
              this.options.labelField
            ] || ''
          : ''
      },
    },
  },
  methods: {
    setLocalValue(val: string) {
      this.localValue = val
    },
    refresh() {
      this.entity[this.options.title] &&
        this.putInBatchQueue([
          {
            id: this.entity[this.options.title],
            type: this.options.entityType,
          },
        ])
    },
  },
  async mounted() {
    this.refresh()
  },
})
