


















import UpdateEntityMixin from './UpdateEntityMixin'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
// @ts-ignore
const { url } = createCamerasConnection()

export default UpdateEntityMixin.extend({
  name: 'File',
  props: {
    attachment: Object as () => Attachment,
  },
  data() {
    return {}
  },
  methods: {
    download() {
      window.open(url.href + `v1/attachments/${this.userTaskId}/${this.attachment.id}`, '_blank')
    },
  },
  mounted() {},
})
