(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("primevue/inputtext"), require("primevue/calendar"), require("primevue/textarea"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("vue-router"), require("primevue/toastservice"), require("vue-i18n"), require("vue"), require("@netvision/lib-api-gateway"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/dialog"), require("primevue/overlaypanel"), require("primevue/button"));
	else if(typeof define === 'function' && define.amd)
		define(["primevue/inputtext", "primevue/calendar", "primevue/textarea", "primevue/dropdown", "primevue/tooltip", "single-spa-vue", "vue-router", "primevue/toastservice", "vue-i18n", "vue", "@netvision/lib-api-gateway", "primevue/toast", "primevue/progressbar", "primevue/dialog", "primevue/overlaypanel", "primevue/button"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("primevue/inputtext"), require("primevue/calendar"), require("primevue/textarea"), require("primevue/dropdown"), require("primevue/tooltip"), require("single-spa-vue"), require("vue-router"), require("primevue/toastservice"), require("vue-i18n"), require("vue"), require("@netvision/lib-api-gateway"), require("primevue/toast"), require("primevue/progressbar"), require("primevue/dialog"), require("primevue/overlaypanel"), require("primevue/button")) : factory(root["primevue/inputtext"], root["primevue/calendar"], root["primevue/textarea"], root["primevue/dropdown"], root["primevue/tooltip"], root["single-spa-vue"], root["vue-router"], root["primevue/toastservice"], root["vue-i18n"], root["vue"], root["@netvision/lib-api-gateway"], root["primevue/toast"], root["primevue/progressbar"], root["primevue/dialog"], root["primevue/overlaypanel"], root["primevue/button"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__025a__, __WEBPACK_EXTERNAL_MODULE__0482__, __WEBPACK_EXTERNAL_MODULE__12bc__, __WEBPACK_EXTERNAL_MODULE__3746__, __WEBPACK_EXTERNAL_MODULE__3b23__, __WEBPACK_EXTERNAL_MODULE__4171__, __WEBPACK_EXTERNAL_MODULE__6389__, __WEBPACK_EXTERNAL_MODULE__68f9__, __WEBPACK_EXTERNAL_MODULE__85b3__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_bb6b__, __WEBPACK_EXTERNAL_MODULE_d26e__, __WEBPACK_EXTERNAL_MODULE_d833__, __WEBPACK_EXTERNAL_MODULE_dec9__, __WEBPACK_EXTERNAL_MODULE_dffb__, __WEBPACK_EXTERNAL_MODULE_f095__) {
return 