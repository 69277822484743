



















import Vue from 'vue'
import { mapStateTyped } from '@/store'
import MarkerCircle from '../common/MarkerCircle.vue'

const EVENT_TYPE_MAP = {
  TboEvent: 'TboEventType',
  AssignmentEvent: 'AssignmentEventType',
} as Record<string, string>

const EVENT_TYPE_FIELD = {
  TboEvent: 'tboEventType',
  AssignmentEvent: 'eventType',
} as Record<string, string>
type Path = { path: string; query: Record<string, string> }
export default Vue.extend({
  name: 'Event',
  props: {
    entity: Object as () => AnalyticsEvent,
  },
  components: {
    MarkerCircle,
  },
  data() {
    return {}
  },
  inject: ['props'],
  computed: {
    ...mapStateTyped(['eventTypes']),
    path(): Path {
      const path = this.props.eventsLink || 'events'
      return { path, query: { event: `"${this.entity.id}"` } }
    },
    eventType(): EventType | null {
      return this.eventTypes
        ? this.eventTypes[this.entity?.[EVENT_TYPE_FIELD[this.entity.type]]]
        : null
    },
  },
  methods: {},
  mounted() {
    this.$store.dispatch('fetchEventTypes', EVENT_TYPE_MAP[this.entity.type])
  },
})
