import { render, staticRenderFns } from "./StatusIcon.vue?vue&type=template&id=6fd2bdd2&scoped=true&"
import script from "./StatusIcon.vue?vue&type=script&lang=ts&"
export * from "./StatusIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd2bdd2",
  null
  
)

export default component.exports