





























import Vue from 'vue'
import MarkerCircle from '../common/MarkerCircle.vue'
import Card from './cards/Card.vue'
import CameraCard from './cards/CameraCard.vue'
import Label from '../common/Label.vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'MapEntity',
  components: {
    MarkerCircle,
  },
  props: {
    entity: Object as () => {
      id: string
      icon: Icon
      [key: string]: any
    },
    meta: Object,
  },
  data() {
    return {
      overlayVisible: false,
      componentMap: {
        Camera: CameraCard,
        ComplexObject: Card,
        TboContainerArea: Card,
        TboEvent: Label,
      } as Record<string, any>,
    }
  },
  computed: { ...mapState(['idsMap', 'visiblePopup']) },
  methods: {
    toggle() {
      this.overlayVisible = true
      if (this.visiblePopup !== this.entity.id) {
        this.$store.commit('setValue', ['visiblePopup', this.entity.id])
      } else {
        this.$store.commit('setValue', ['visiblePopup', ''])
      }
    },
  },
})
