


























































































import UpdateEntityMixin from './common/UpdateEntityMixin'

export default UpdateEntityMixin.extend({
  name: 'TitleDescription',
  props: {
    entity: Object,
  },
  data() {
    return {
      editMode: false,
      notSavedValue: {
        title: this.entity.title || '',
        description: this.entity.description || '',
      } as Record<string, string>,
    }
  },
  computed: {
    localValue: {
      set(val: Record<string, string>) {
        const { title, description } = val
        this.updateEntity({
          title,
          description,
        })
      },
      get(): Record<string, string> {
        return {
          title: this.entity.title || '',
          description: this.entity.description || '',
        }
      },
    },
  },
  methods: {
    onBlure() {
      if (this.createMode) {
        const { title, description } = this.notSavedValue
        this.commitEntity({
          title: title?.trim(),
          description: description?.trim(),
        })
      }
    },
  },
})
