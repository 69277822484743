






















import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
  name: 'MarkerCircle',
  props: {
    color: String,
  },
  computed: {
    ...mapState(['isDarkTheme']),
    localColor(): string {
      switch (true) {
        case !this.color:
          return '#3C72FF'
        case this.color.includes('--'):
          return `var(${this.color})`
        default:
          return this.color
      }
    },
  },
})
