












import Vue from 'vue'
import InfoRow from './fields/InfoRow.vue'
import ReferenceField from './fields/ReferenceField.vue'
import StatusField from './fields/StatusField.vue'
import DateField from './fields/DateField.vue'
import MultiReferenceField from './fields/MultiReferenceField.vue'
import ParticipantField from './fields/ParticipantsField.vue'
import OrganizationField from './fields/OrganizationField.vue'

export default Vue.extend({
  name: 'FieldsList',
  props: {
    fields: Array as () => { title: string; type: string }[],
    entity: Object,
  },
  data() {
    return {
      InfoRow,
      componentMap: {
        string: InfoRow,
        ref: ReferenceField,
        status: StatusField,
        date: DateField,
        multiRef: MultiReferenceField,
        organization: OrganizationField,
        participant: ParticipantField,
      } as Record<string, any>,
    }
  },
})
