








import Vue from 'vue'

export default Vue.extend({
  name: 'InfoRow',
  props: {
    entity: Object,
    title: String,
    options: Object,
  },
})
