































import UpdateEntityMixin from '../common/UpdateEntityMixin'
import { dateTimeFormats } from '../../translations/dateTimeLocale'

export default UpdateEntityMixin.extend({
  name: 'DateField',
  props: {
    entity: Object,
    options: Object as () => {
      title: string
      isDueDate: boolean
    },
  },
  data() {
    return {
      date: null,
      dateLocale: dateTimeFormats[this.$i18n.locale],
    }
  },
  computed: {
    localValue: {
      set(val: Date | null) {
        if (val?.getTime() !== this.localValue?.getTime()) {
          const method = this.createMode ? 'commitEntity' : 'updateEntity'
          this?.[method]({
            [this.options.title]: val,
          })
        }
      },
      get(): Date | null {
        const d = new Date(this.entity[this.options.title] ?? '')
        return isNaN(d.getTime()) ? null : d
      },
    },
  },
  methods: {
    clearDate() {
      this.localValue = null
    },
  },
})
