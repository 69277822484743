export const RUSSIAN_TRANSLATIONS = {
  search: 'Поиск',
  nothingFound: 'Ничего не найдено',
  loading: 'Загрузка...',
  clear: 'Очистить',
  unnamed: 'Безымянный',
  notDefined: 'Не определен',
  load: 'Загрузить',
  uploadFile: 'Загрузить файл',
  loadMessage: 'Перетащите файлы с компьютера или нажмите кнопку загрузить',
  cancel: 'Отменить',
  attachments: 'Файлы',
  comments: 'Комментарии',
  commentary: 'Комментарий...',
  deleteFile: 'Удалить файл',
  deleteEntity: 'Удалить объект',
  deleteTitle: 'Удалить',
  send: 'Отправить',
  date: 'Дата',
  deleteRelated: 'Удалить связь',
  fileFormatForbiden: 'формат файла запрещен',
  message: {
    unknownError: 'Возникла непредвиденная ошибка',
    fileTooLarge: 'Загружаемый файл не должен быть более 100 Мб',
    filenameCantBeEmpty: 'Отсутвует имя файла',
  },
  button: {
    save: 'Сохранить',
    cancel: 'Отмена',
    yes: 'Да',
    no: 'Нет',
  },
}
