









































































































import UpdateEntityMixin from './common/UpdateEntityMixin'
import FieldsList from './FieldsList.vue'
import TaskTypeField from './fields/TaskTypeField.vue'
import RelatedEntities from './RelatedEntities.vue'
import Attachments from './Attachments.vue'
import Comment from './Comment.vue'
import NewComment from './NewComment.vue'
import TitleDescription from './TitleDescription.vue'
import { formatDate, uuid } from '@/utils'
import { mapState } from 'vuex'
import { createCamerasConnection } from '@netvision/lib-api-gateway'

export default UpdateEntityMixin.extend({
  name: 'UserTaskDialog',
  props: {
    userTask: Object as () => UserTask,
    rightBarFields: Array as () => WidgetProps['meta']['viewCard']['fields'],
  },
  components: {
    FieldsList,
    TaskTypeField,
    RelatedEntities,
    Attachments,
    Comment,
    NewComment,
    TitleDescription,
  },
  computed: {
    ...mapState(['loading', 'createMode']),
  },
  data() {
    return {
      visible: true,
    }
  },
  methods: {
    formatDate,
    saveEntity() {
      this.tryCatch(async () => {
        const newUserTask = { ...this.userTask } as UserTask
        newUserTask.id = uuid()
        newUserTask.type = 'UserTask'
        newUserTask.tboEventId = 'null'
        this.$eventBus.$emit('commitEntity', { id: newUserTask.id })
        await createCamerasConnection().v2.createEntity(newUserTask, {
          keyValues: true,
        })
        this.$store.commit('setValue', ['createMode', false])
      })
    },
  },
})
