















import Vue from 'vue'
import UserTaskDialog from './components/UserTaskDialog.vue'
import { getSharedNotificationSocket } from '@netvision/lib-api-gateway'
import { makeDictFromArray } from '@/utils'
import { userTaskType, userTaskStatus } from '@/constants'

export default Vue.extend({
  name: 'App',
  components: {
    UserTaskDialog,
  },
  data() {
    const props = this.$parent?.props as WidgetProps
    return {
      props,
      rightBarFields: props?.meta?.viewCard?.fields,
      themeUnsubscribe: () => {},
      socketListenerUbsubscribe: () => {},
    }
  },
  provide(): any {
    return {
      props: this.props,
      dictionaries: this.props?.meta?.viewCard?.dictionaries
        ? {
            userTaskType: makeDictFromArray(
              this.props.meta.viewCard.dictionaries.userTaskTypes || {},
              'title',
            ),
            userTaskStatus: makeDictFromArray(
              this.props.meta.viewCard.dictionaries.userTaskStatuses || {},
              'title',
            ),
          }
        : {
            userTaskType,
            userTaskStatus,
          },
      spaParent: this.$parent || {},
      areas: this.props.areas,
    }
  },
  methods: {
    async createSubscription() {
      this.socketListenerUbsubscribe =
        await getSharedNotificationSocket().addListener(
          'UserTask',
          (entity: UserTask) => {
            if (entity.id === this.props.userTask.id) {
              this.props.userTask = entity
            }
          },
        )
    },
  },
  beforeMount() {
    this.$store.commit('reset')
    this.$store.commit('setValue', [
      'userTaskId',
      this.$parent?.props.userTask.id,
    ])
    // Mixing locales
    if (this.props?.meta?.localeRu) {
      // @ts-ignore
      this.$i18n.vm.messages[this.$i18n.locale] = {
        ...this.$i18n.messages[this.$i18n.locale],
        ...this.props.meta.localeRu,
      }
    }

    // Mixing Permissions
    if (this.props.permissionScopes) {
      this.$store.commit('setValue', [
        'permissionScopes',
        new Map([...this.props.permissionScopes]),
      ])
    }
    // Entity Watcher
    if (this.props?.userTask?.id) {
      this.createSubscription()
    } else {
      this.$store.commit('setValue', ['createMode', true])
    }
    // Theme watcher
    const themeEl = document.getElementById('theme') as HTMLLinkElement & {
      setTheme: () => void
    }
    this.$store.commit('setValue', [
      'isDarkTheme',
      themeEl.getAttribute('theme-name') === 'dark',
    ])
    const themeSubscribe = (func: (newValue: boolean) => void) => {
      const listener = (e: any) => func(e.detail === 'dark')
      themeEl.addEventListener('update', listener)
      return () => themeEl.removeEventListener('update', listener)
    }
    this.themeUnsubscribe = themeSubscribe((newValue) => {
      this.$store.commit('setValue', ['isDarkTheme', newValue])
    })
    Vue.prototype.$eventBus = new Vue()
    this.$eventBus.$on(
      'commitEntity',
      async (payload: Record<string, unknown>) => {
        this.$parent.props.userTask = {
          ...this.$parent.props.userTask,
          ...payload,
        }
        if ('id' in payload) {
          this.$store.commit('setValue', [
            'userTaskId',
            this.$parent?.props.userTask.id,
          ])
          this.createSubscription()
        }
      },
    )
  },
  destroyed() {
    this.socketListenerUbsubscribe()
    this.themeUnsubscribe()
  },
})
