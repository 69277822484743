































import BatchQueue from './BatchQueue'
import File from './File.vue'
import ImageFile from './ImageFile.vue'

const IMAGE_FORMATS = new Set([
  'bmp',
  'ico',
  'jpg',
  'tiff',
  'jpeg',
  'svg',
  'webp',
  'png',
  'gif',
])
export default BatchQueue.extend({
  name: 'AttachmentsList',
  props: {
    attachments: Array as () => Attachment[],
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteEntityId: '',
      displayDeleteDialog: false,
    }
  },
  methods: {
    component(extention: string) {
      return extention && IMAGE_FORMATS.has(extention.toLowerCase())
        ? ImageFile
        : File
    },
    deleteEntity() {
      this.updateEntity({
        deleteAttachments: [
          {
            id: this.deleteEntityId,
          },
        ],
      })
    },
  },
})
