



































import UpdateEntityMixin from '../common/UpdateEntityMixin'

export default UpdateEntityMixin.extend({
  name: 'TaskTypeField',
  props: {
    entity: Object as () => UserTask,
    options: Object as () => {
      title: keyof UserTask
      scope: string
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    localValue: {
      set(val: string) {
        // @ts-ignore
        if (val !== this.entity[this.options.title]) {
          const method = this.createMode ? 'commitEntity' : 'updateEntity'
          this?.[method]({
            [this.options.title]: val,
          })
        }
      },
      get(): ValueOf<UserTask> | string {
        // @ts-ignore
        return this.entity[this.options.title] || ''
      },
    },
    dictOption(): UserTaskType {
      // @ts-ignore
      return this.dictionaries.userTaskType[this.localValue] || {}
    },
    dictOptions(): UserTaskType[] {
      // @ts-ignore
      return Object.values(this.dictionaries.userTaskType || {})
    },
  },
  inject: ['dictionaries'],
  mounted() {
    if (this.createMode) {
      this.commitEntity({ [this.options.title]: this.dictOptions[0].title })
    }
  },
})
