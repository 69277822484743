



import Vue from 'vue'

export default Vue.extend({
  name: 'CameraCard',
  props: {
    entity: Object as () => Camera,
    options: Object as () => {
      showArchivePlayerContainer: (val: boolean) => void
      showAssignmentDialogContainer: (val: boolean) => void
      assignmentTypes: string[]
      compactViewSubscriptor: any
      isCompactView: boolean
      permissionScopes: Map<string, string[]>
      toast: any
    },
  },
  data() {
    return {
      unmount: () => {},
    }
  },
  inject: ['spaParent', 'areas'],
  methods: {
    mountCard(): () => void {
      let ref = this.$refs[`card-${this.entity.id}`]
      ref = Array.isArray(ref) ? ref[0] : ref
      // @ts-ignore
      const camCard = this.areas.find((e: any) => e.name === 'Camera')
      if (camCard !== undefined && ref !== undefined) {
        // @ts-ignore
        return this.spaParent.mountChildren(
          ref,
          camCard.children.map((e: any) => {
            return {
              ...e,
              props: {
                ...e.props,
                camera: this.entity,
                assignedAnalytics: [],
                options: {
                  showArchivePlayerContainer: (val: boolean) => {},
                  showAssignmentDialogContainer: (val: boolean) => {},
                  assignmentTypes: [],
                  compactViewSubscriptor: {},
                  isCompactView: false,
                  permissionScopes: new Map(),
                  toast: this.$toast,
                },
              },
            }
          }),
        )
      } else {
        return () => {}
      }
    },
  },
  mounted() {
    this.unmount = this.mountCard()
  },
  beforeDestroy() {
    this.unmount()
  },
})
