import Vue from 'vue'
import { debounceFuncArr, debouncePromise, capitalize } from '@/utils'
import {
  createCamerasConnection,
  getPermissionsByIdsMap,
  getPermittedScopes,
} from '@netvision/lib-api-gateway'
import { mapState } from 'vuex'

const getPermIds = debounceFuncArr(getPermissionsByIdsMap, 100)

export default Vue.extend({
  name: 'UpdateEntityMixin',
  computed: {
    ...mapState(['userTaskId', 'createMode', 'permissionScopes']),
  },
  methods: {
    async tryCatch(func: () => {}) {
      try {
        return await func()
      } catch (error) {
        this.errorToast(error)
      }
    },
    te(str: string, def: string) {
      return this.$te(str) ? this.$t(str) : capitalize(def)
    },
    updateEntity(optionMixin: Record<string, any>) {
      return this.tryCatch(async () => {
        await createCamerasConnection().v2.updateEntityAttributes(
          {
            id: this.userTaskId,
            type: 'UserTask',
            ...optionMixin,
          },
          {
            keyValues: true,
          },
        )
      })
    },
    commitEntity(optionMixin: Record<string, any>) {
      this.$eventBus.$emit('commitEntity', optionMixin)
    },
    canI(
      scope: string,
      entity: { id: string; resourceId: string } | null | any = null,
    ) {
      const scopes = this.permissionScopes.get(entity ? entity.id : scope)
      if (scopes) {
        return Array.isArray(scopes) ? scopes.includes(scope) : false
      }
      this.fetchPermissions(scope, entity)
    },
    async fetchPermissions(
      scope: string,
      entity: { id: string; resourceId?: string } | null = null,
    ) {
      if (entity?.resourceId) {
        const permIds = (await getPermIds([entity.resourceId])) as Map<
          string,
          string[]
        >
        if (permIds?.size > 0) {
          this.$store.commit('setValue', [
            'permissionScopes',
            new Map([...this.permissionScopes, ...permIds]),
          ])
        }
      } else {
        const result = await debouncePromise(
          async () => await getPermittedScopes([scope]),
          1000,
          scope,
        )
        this.permissionScopes.set(scope, result)
      }
    },
    errorToast(error: any) {
      console.error(error)
      this.$toast.add({
        severity: 'error',
        summary: this.$t('error'),
        detail: error.message,
        life: 5000,
      })
    },
  },
})
