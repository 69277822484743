























import { mapState } from 'vuex'
import BatchQueue from './common/BatchQueue'
import UploadFile from './common/UploadFile.vue'
import AttachmentsList from './common/AttachmentsList.vue'

export default BatchQueue.extend({
  name: 'Attachments',
  components: {
    UploadFile,
    AttachmentsList,
  },
  props: {
    entity: Object as () => UserTask,
  },
  computed: {
    ...mapState(['idsMap']),
  },
  methods: {
    isArray: Array.isArray,
  },
})
