





























import BatchQueue from './common/BatchQueue'
import UploadFile from './common/UploadFile.vue'
import AttachmentsList from './common/AttachmentsList.vue'
import { uuid } from '@/utils'

export default BatchQueue.extend({
  name: 'NewComment',
  components: {
    UploadFile,
    AttachmentsList,
  },
  props: {
    entity: Object as () => UserTask,
  },
  data() {
    return {
      commentAttachmentRelations: [] as string[],
      comment: {
        text: '',
      } as Partial<Comment>,
    }
  },
  computed: {
    attachments(): Attachment[] {
      return Array.isArray(this.entity.attachments)
        ? this.entity.attachments.filter(({ id }) =>
            this.commentAttachmentRelations?.includes(id),
          )
        : []
    },
  },
  watch: {
    comment: {
      handler({ text }) {
        if (text.length > 50000) {
          this.comment.text = this.comment.text?.slice(0, 50000)
        }
      },
      deep: true,
    },
  },
  methods: {
    uploaded(rel: string) {
      this.commentAttachmentRelations.push(rel)
    },
    async leftComment() {
      const id = uuid()
      await this.updateEntity({
        addComments: [
          {
            id,
            ...this.comment,
          },
        ],
      })
      this.commentAttachmentRelations.length &&
        this.updateEntity({
          addCommentAttachmentRelations: this.commentAttachmentRelations.map(
            (attachmentId) => ({
              commentId: id,
              attachmentId,
            }),
          ),
        })
      this.comment = { text: '' }
      this.commentAttachmentRelations = []
    },
  },
})
