var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{style:({
    width: '960px',
    maxHeight: '95vh',
  }),attrs:{"visible":_vm.visible,"position":"center","contentStyle":{
    height: '100%',
    position: 'relative',
    top: '-1rem',
  },"showHeader":true,"appendTo":"body","closeOnEscape":true,"closable":true,"dismissableMask":false,"modal":true},on:{"update:visible":function($event){_vm.visible=$event},"hide":function($event){return _vm.$emit('onClose')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('ProgressBar',{style:({
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: _vm.loading ? 1 : 0,
        transition: 'opacity 0.5s',
        height: 'calc(2rem / var(--bfs))',
        width: '100%',
      }),attrs:{"mode":"indeterminate"}})]},proxy:true}])},[_c('div',{staticClass:"p-d-flex",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"left-side p-d-flex p-flex-column"},[_c('div',{staticClass:"left-side__header p-d-flex p-ai-center"},[_c('TaskTypeField',{attrs:{"data-cy":"opened-task-type","entity":_vm.userTask,"options":{
            title: 'taskType',
            scope: 'dasdasd',
          }}}),(_vm.userTask.serialNumber)?_c('span',{staticClass:"task-id",attrs:{"data-cy":"opened-task-number"},domProps:{"textContent":_vm._s(("№ " + (_vm.userTask.serialNumber)))}}):_vm._e(),_c('div',{staticStyle:{"flex-grow":"1"}}),_c('span',{staticClass:"task-date",attrs:{"data-cy":"opened-task-date"},domProps:{"textContent":_vm._s(_vm.formatDate(_vm.userTask.created))}})],1),_c('div',{staticClass:"left-side__title p-d-flex p-flex-column p-py-1"},[_c('TitleDescription',{attrs:{"entity":_vm.userTask}})],1),(!_vm.createMode)?_c('RelatedEntities',{staticClass:"p-mt-1",attrs:{"entity":_vm.userTask,"options":{
          title: 'relatedEntities',
        }}}):_vm._e(),(!_vm.createMode)?_c('Attachments',{staticClass:"p-mt-2",attrs:{"data-cy":"opened-task-attachments","entity":_vm.userTask}}):_vm._e(),(!_vm.createMode)?_c('div',{staticClass:"p-mt-2"},[_c('span',{staticClass:"label",attrs:{"data-cy":"comment-title"},domProps:{"textContent":_vm._s(_vm.$t('comments'))}}),(_vm.userTask.comments)?_c('div',{staticClass:"p-d-flex p-flex-column p-mt-2",attrs:{"data-cy":"comments-list"}},_vm._l((_vm.userTask.comments),function(comment){return _c('Comment',{key:comment.id,attrs:{"comment":comment,"entity":_vm.userTask}})}),1):_vm._e()]):_vm._e(),(!_vm.createMode && _vm.canI('AddComments', _vm.userTask))?_c('div',{staticClass:"p-mt-2"},[_c('NewComment',{attrs:{"data-cy":"add-comment-block","entity":_vm.userTask}})],1):_vm._e(),_c('div',{staticStyle:{"flex-grow":"1"}}),(_vm.createMode)?_c('div',{staticClass:"p-my-2"},[_c('Button',{staticClass:"p-mr-2",attrs:{"data-cy":"button-save-comment","label":_vm.$t('button.save'),"disabled":!(_vm.userTask.title && _vm.userTask.title.length > 0)},on:{"click":function($event){return _vm.saveEntity()}}}),_c('Button',{staticClass:"p-button-secondary p-button-outlined p-mr-1",attrs:{"data-cy":"button-cancel-comment","label":_vm.$t('cancel')},on:{"click":function($event){_vm.visible = false}}})],1):_vm._e()],1),_c('div',{staticClass:"right-side",attrs:{"data-cy":"right-side-info"}},[_c('FieldsList',{attrs:{"entity":_vm.userTask,"fields":_vm.rightBarFields}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }