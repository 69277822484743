



























































import { mapState } from 'vuex'
import BatchQueue from './common/BatchQueue'
import MapEntity from './entities/MapEntity.vue'
import Event from './entities/Event.vue'
import { createCamerasConnection } from '@netvision/lib-api-gateway'

type RelatedEntity = { id: string; type: string }

export default BatchQueue.extend({
  name: 'RelatedEntities',
  props: {
    entity: Object,
    options: Object as () => {
      title: string
      scope: string
      entityType: string
      labelField: string
      iconClass: string
    },
  },
  data() {
    return {
      componentMap: {
        Camera: MapEntity,
        ComplexObject: MapEntity,
        TboContainerArea: MapEntity,
        TboEvent: Event,
        AssignmentEvent: Event,
      } as Record<string, any>,
      entityType: undefined as string | undefined,
      deleteEntityId: '',
      displayDeleteDialog: false,
      loading: false,
      valueOptions: [] as any,
      metaTypes: {} as Record<string, any>,
    }
  },
  computed: {
    ...mapState(['idsMap']),
    localValue(): RelatedEntity[] {
      return (this.entity[this.options.title] || []) as RelatedEntity[]
    },
  },
  methods: {
    addRelatedEntity(val: string) {
      this.updateEntity({
        addRelatedEntities: [
          {
            id: val,
          },
        ],
      })
    },
    deleteEntity() {
      this.updateEntity({
        deleteRelatedEntities: [
          {
            id: this.deleteEntityId,
          },
        ],
      })
    },
  },
  async created() {
    const relatedEntities = this.entity[this.options.title]
    if (Array.isArray(relatedEntities) && relatedEntities.length > 0) {
      const entityType = Array.from(
        new Set(relatedEntities.map(({ type }: any) => type)),
      ) as string[]
      this.entityType = entityType.join(',')
      try {
        for (const type of entityType) {
          const { entity } = await createCamerasConnection().v2.getEntity({
            id: `EntityTypeMetadata:${type}`,
            type: 'EntityTypeMetadata',
            keyValues: true,
          })
          this.metaTypes[type] = entity
        }
      } catch (error) {
        this.errorToast(error)
      }
      this.putInBatchQueue(relatedEntities)
    }
  },
})
