




































import BatchQueue from './common/BatchQueue'
import { formatDate } from '@/utils'
import AttachmentsList from './common/AttachmentsList.vue'
import { mapStateTyped } from '@/store'

export default BatchQueue.extend({
  name: 'Comment',
  components: {
    AttachmentsList,
  },
  props: {
    entity: Object as () => UserTask,
    comment: Object as () => Comment,
  },
  data() {
    return {}
  },
  computed: {
    ...mapStateTyped(['idsMap']),
    user(): User {
      return (
        this.comment.commentator
          ? // @ts-ignore
            this.idsMap[this.comment.commentator]
          : { fullName: this.$t('unnamed') }
      ) as User
    },
    commentAttachmentRelationsIds(): Uuid[] {
      return (
        (this.entity.commentAttachmentRelations &&
          this.entity.commentAttachmentRelations
            .filter(({ commentId }) => commentId === this.comment.id)
            .map(({ attachmentId }) => attachmentId)) ||
        []
      )
    },
    attachments(): Attachment[] {
      return Array.isArray(this.entity.attachments)
        ? this.entity.attachments.filter(({ id }) =>
            this.commentAttachmentRelationsIds.includes(id),
          )
        : []
    },
  },
  methods: {
    formatDate,
  },
  async mounted() {
    this.comment.commentator &&
      this.putInBatchQueue([{ id: this.comment.commentator, type: 'User' }])
  },
})
