



















































import { mapState } from 'vuex'
import BatchQueue from '../common/BatchQueue'
import User from '../common/User.vue'
import Label from '../common/Label.vue'
import { capitalize } from '@/utils'

export default BatchQueue.extend({
  name: 'MultiReferenceField',
  props: {
    entity: Object,
    options: Object as () => {
      title: string
      scope: string
      entityType: string
      labelField: string
    },
  },
  data() {
    return {
      Label,
      componentMap: {
        User,
        Label,
      },
      someVal: '',
      deleteEntityId: '',
      displayDeleteDialog: false,
    }
  },
  computed: {
    ...mapState(['idsMap']),
    localValue: {
      set(val: Uuid) {
        this.updateEntity({
          [`add${capitalize(this.options.title)}`]: [val],
        })
      },
      get(): Uuid[] {
        const val = this.entity[this.options.title]
        if (!Array.isArray(val)) return []
        const notFetchedEntities = this.entity[this.options.title].filter(
          (id: Uuid) => !this.idsMap[id],
        )
        if (notFetchedEntities.length > 0) {
          this.putInBatchQueue(
            notFetchedEntities.map((id: Uuid) => ({
              id,
              type: this.options.entityType,
            })),
          )
        }
        return this.entity[this.options.title]
      },
    },
  },
  methods: {
    capitalize,
    deleteEntity() {
      if (this.createMode) {
        this.commitEntity({
          [this.options.title]: this.entity[this.options.title].filter(
            (e: string) => e !== this.deleteEntityId,
          ),
        })
      } else {
        this.updateEntity({
          [`delete${capitalize(this.options.title)}`]: [this.deleteEntityId],
        })
      }
    },
    onChange(val: Uuid) {
      if (this.createMode) {
        this.commitEntity({
          [this.options.title]: [
            ...(this.entity[this.options.title] || []),
            val,
          ],
        })
      } else {
        this.localValue = val
      }
    },
  },
})
