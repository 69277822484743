export const userTaskStatus = {
  Opened: {
    title: 'Opened',
    isResolved: false,
    icon: {
      iconClass: 'mdi-circle-medium',
      color: '#3C72FF',
    },
    description: '',
    scope: 'SetAsOpenedUserTask',
    commandOnDrop: 'SetAsOpened',
  },
  Review: {
    title: 'Review',
    icon: {
      iconClass: 'mdi-circle-medium',
      color: '#FBA847',
    },
    isResolved: false,
    description: '',
    scope: 'SetAsReviewUserTask',
    commandOnDrop: 'SetAsReview',
  },
  Rejected: {
    title: 'Rejected',
    icon: {
      iconClass: 'mdi-circle-medium',
      color: '#FF0000',
    },
    isResolved: false,
    description: '',
    scope: 'SetAsRejectedUserTask',
    commandOnDrop: 'SetAsRejected',
  },
  Done: {
    title: 'Done',
    icon: {
      iconClass: 'mdi-circle-medium',
      color: '#43FF00',
    },
    isResolved: true,
    description: '',
    scope: 'SetAsDoneUserTask',
    commandOnDrop: 'SetAsDone',
  },
}
export const userTaskType = {
  prescription: {
    title: 'prescription',
    icon: {
      iconClass: 'mdi-circle-medium',
      color: 'FBA847',
    },
  },
}
