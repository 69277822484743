import UpdateEntityMixin from './UpdateEntityMixin'
import { debounce } from '@/utils'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
import { mapStateTyped } from '@/store'

type BatchQueue = { id: Uuid; type: string }[]
export default UpdateEntityMixin.extend({
  name: 'ButchQueue',
  computed: {
    ...mapStateTyped(['idsMap', 'permissionScopes', 'batchQueue']),
  },
  watch: {
    batchQueue(val: BatchQueue) {
      if (val.length > 0) {
        debounce(this.fetchBatch, 300, 'fetchBatch')
      }
    },
  },
  methods: {
    async fetchBatch() {
      try {
        this.$store.commit('setValue', ['loading', true])
        const {
          results,
        }: {
          results: {
            id: Uuid
            [k: string]: any
          }[]
        } = await createCamerasConnection().v2.batchQuery(
          {
            entities: this.batchQueue,
          },
          {
            limit: 1000,
            keyValues: true,
          },
        )
        const idsMap = { ...this.idsMap } as any
        results.forEach((e) => {
          idsMap[e.id] = e
        })
        this.$store.commit('setValue', ['idsMap', idsMap])
        this.$store.commit('setValue', [
          'batchQueue',
          this.batchQueue.filter(({ id }) => !idsMap[id]),
        ])
      } catch (error) {
        console.error(error)
        this.$store.commit('setValue', ['batchQueue', []])
      } finally {
        this.$store.commit('setValue', ['loading', false])
      }
    },
    putInBatchQueue(ids: { id: Uuid; type: string }[]) {
      const newIds = ids.filter(
        (e) =>
          // @ts-ignore
          !this.idsMap[e.id] &&
          !this.batchQueue.find(({ id }: any) => id === e.id),
      )
      if (newIds.length > 0) {
        this.$store.commit('setValue', [
          'batchQueue',
          [...this.batchQueue, ...newIds],
        ])
      }
    },
  },
})
