











import Vue from 'vue'
export default Vue.extend({
  name: 'StatusIcon',
  props: {
    entity: Object as () => Icon,
  },
})
