



import Vue from 'vue'

export default Vue.extend({
  name: 'Card',
  props: {
    entity: Object,
    isCompactView: Boolean,
    mainAction: Object,
    meta: Object,
  },
  data() {
    return {
      unmount: () => {},
    }
  },
  inject: ['spaParent', 'areas'],
  methods: {
    mountCard(): () => void {
      let ref = this.$refs[`card-${this.entity.id}`]
      ref = Array.isArray(ref) ? ref[0] : ref
      // @ts-ignore
      const objectCard = this.areas.find((area: any) => area.name === 'Card')
      if (objectCard && ref) {
        // @ts-ignore
        return this.spaParent.mountChildren(
          ref,
          objectCard.children.map((e: any) => {
            return {
              ...e,
              props: {
                entity: this.entity,
                toast: this.$toast,
                meta: this.meta,
              },
            }
          }),
        )
      } else {
        return () => {}
      }
    },
  },
  mounted() {
    this.unmount = this.mountCard()
  },
  beforeDestroy() {
    this.unmount()
  },
})
