







































import UpdateEntityMixin from './UpdateEntityMixin'
import { createCamerasConnection } from '@netvision/lib-api-gateway'
// @ts-ignore
const { url } = createCamerasConnection()

export default UpdateEntityMixin.extend({
  name: 'ImageFile',
  props: {
    attachment: Object as () => Attachment,
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    imageHrefPreview(): string {
      return `${url.href}v1/attachments/${this.userTaskId}/${this.attachment.id}?mode=preview`
    },
    imageHref(): string {
      return `${url.href}v1/attachments/${this.userTaskId}/${this.attachment.id}`
    },
  },
})
