import { IEntity, createCamerasConnection } from '@netvision/lib-api-gateway'
import { mapState } from 'vuex'

const initialState = () => {
  return {
    idsMap: { '': null } as Record<Uuid, IEntity>,
    myId: undefined as Uuid[] | undefined,
    userTaskId: '',
    loading: false,
    createMode: false as boolean,
    visiblePopup: '',
    batchQueue: [] as { id: Uuid; type: string }[],
    fetchEventTypesStatus: 'notStarted' as 'fetched' | 'notStarted',
    eventTypes: null as Record<string, EventType> | null,
    permissionScopes: new Map() as Map<string, string[]>,
  } as const
}

type State = ReturnType<typeof initialState>
export const mapStateTyped = <
  T extends keyof State,
  G extends { [Key in T]: () => State[Key] },
>(
  keys: T[],
): G => {
  return { ...mapState(keys) } as G
}

type actionArguments = {
  commit: <T extends keyof State>(
    method: string,
    payload: [T, State[T]],
  ) => void
  state: State
  dispatch: (arh: actionArguments, payload: any) => void
}
export default {
  state: initialState(),
  mutations: {
    setValue(state: State, [key, value]: [keyof State, unknown]) {
      // @ts-ignore
      state[key] = value
    },
    reset(state: State) {
      const s = initialState()
      Object.keys(s).forEach((key) => {
        // @ts-ignore
        state[key] = s[key]
      })
    },
  },
  actions: {
    async fetchEventTypes({ commit, state }: actionArguments, type: string) {
      if (state.fetchEventTypesStatus === 'fetched') return
      commit('setValue', ['fetchEventTypesStatus', 'fetched'])
      const { results } = (await createCamerasConnection().v2.listEntities({
        type,
        keyValues: true,
        limit: 1000,
      })) as { results: EventType[] }
      results &&
        commit('setValue', [
          'eventTypes',
          results.reduce((acc, eventType) => {
            acc[eventType.name] = eventType
            return acc
          }, {} as Record<string, EventType>),
        ])
    },
  },
}
