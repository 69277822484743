import { render, staticRenderFns } from "./ImageFile.vue?vue&type=template&id=23185999&scoped=true&"
import script from "./ImageFile.vue?vue&type=script&lang=ts&"
export * from "./ImageFile.vue?vue&type=script&lang=ts&"
import style0 from "./ImageFile.vue?vue&type=style&index=0&id=23185999&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23185999",
  null
  
)

export default component.exports