












































import UpdateEntityMixin from '../common/UpdateEntityMixin'
import StatusIcon from '../common/StatusIcon.vue'

export default UpdateEntityMixin.extend({
  name: 'StatusField',
  components: {
    StatusIcon,
  },
  props: {
    entity: Object,
    options: Object as () => {
      title: string
      scope: string
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    localValue: {
      set(val: string) {
        if (val !== this.entity[this.options.title]) {
          // @ts-ignore
          const command = this.dictionaries?.userTaskStatus?.[val]?.commandName
          if (command) {
            this.updateEntity({
              [command]: {},
            })
          }
        }
      },
      get(): string {
        return this.entity[this.options.title] || ''
      },
    },
    status(): UserTaskStatus {
      // @ts-ignore
      return this.dictionaries?.userTaskStatus[this.localValue] || {}
    },
    statuses(): UserTaskStatus[] {
      return Object.values(
        // @ts-ignore
        (this.dictionaries?.userTaskStatus as Record<string, UserTaskStatus>) ||
          {},
      ).filter(({ scope }: UserTaskStatus) => {
        return this.canI(scope, this.entity)
      })
    },
  },
  inject: ['dictionaries'],
  mounted() {
    if (this.createMode) {
      this.commitEntity({ [this.options.title]: this.statuses[0].title })
    }
  },
})
